import React, {useEffect, useContext, useState, useRef} from "react";
import { graphql, Link } from 'gatsby';
import Layout from "../components/layout";
import FluidImage from "../components/fluidImage";
import Seo from 'gatsby-plugin-wpgraphql-seo';
import useModalState from '../helpers/modalState';

import ThemeContext from '../context/ThemeContext'


import * as styleSheet from "./contact.module.scss"

const ContactTemplate = (props) => {

  const { location } = useContext(ThemeContext);
	const [ nearestLoc, setNearestLoc ] = useState(null);

  const [showAppointmentModal, setShowAppointmentModal] = useModalState();
  
  function showModal(){
		if( !showAppointmentModal.modalOpen ){
			setShowAppointmentModal({
                modalOpen: true, 
                zipcode: showAppointmentModal.zipcode,
                greyfinchId: showAppointmentModal.greyfinchId,
                showCallCenterModalOnClose: showAppointmentModal.showCallCenterModalOnClose,
                callCenterModalOpen: showAppointmentModal.callCenterModalOpen
            });
		}
	}

  function handleApptKeyDown(e) {
    if (e.keyCode === 13 || e.keyCode === 32) {
        setShowAppointmentModal({
            modalOpen: true, 
            zipcode: showAppointmentModal.zipcode,
            greyfinchId: showAppointmentModal.greyfinchId,
            showCallCenterModalOnClose: showAppointmentModal.showCallCenterModalOnClose,
            callCenterModalOpen: showAppointmentModal.callCenterModalOpen
        });
		}
  }


  const gFormID = 2


	const {
		data: {
			contact,
			contact: {
				title,
				ACFContact: {
					contact: {
						left: {
							blurb,
							insurancesHeading,
							requestAppointmentLinkText
						},
						right: {
							introContent: {
								blurb: introBlurb,
								heading: introHeading
							},
              contactHeading,
							contactContent: content,
							email: contactEmail,
							call: contactCall,
							visit: contactVisit,
              showContactForm: showForm,
              contactFormHeading: formHeading 
						}
					}
				}
			},
			options: {
				headerSection: {
					ACFHeaderOptions: {
						mainNavigationSettings: {
							mobileNavigationLogo
						} 
					}
				}

			},
      locations: {
				nodes: locations
			},
			insurance: {
				nodes: insurances
			},
			introBGMobile,
			introBGTablet,
			introBGDesktop,
			introBGXL,
		}
	} = props;



  useEffect( () => {
		function sortByDistance(){

			let locationList = [];
	
			locations.forEach((wpLocation, i) => {   
				const distance = getDistanceFromLatLonInMi(wpLocation.birdeyeLocation.location.lat, wpLocation.birdeyeLocation.location.lng, location.lat, location.lng);
				wpLocation.distance = distance;
				locationList.push(wpLocation);
			});
	
			locationList.sort(function(a, b){
				var keyA = a.distance,
					keyB = b.distance;
				// Compare the 2 dates
				if(keyA < keyB) return -1;
				if(keyA > keyB) return 1;
				return 0;
			});

      function getDistanceFromLatLonInMi(lat1,lon1,lat2,lon2) {
				var R = 6371; // Radius of the earth in km
				var dLat = deg2rad(lat2-lat1);  // deg2rad below
				var dLon = deg2rad(lon2-lon1); 
				var a = 
					Math.sin(dLat/2) * Math.sin(dLat/2) +
					Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * 
					Math.sin(dLon/2) * Math.sin(dLon/2)
					; 
				var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
				var d = R * c; // Distance in km
				var miles = d * 0.621371;
				return miles;
			}
	
			function deg2rad(deg) {
				return deg * (Math.PI/180)
			}
	
			// apply sorted list to state
			setNearestLoc(locationList[0]);
		}

		if( location ){
			sortByDistance();
		}
	}, [location, locations])



  const iframeInterval = useRef();

	useEffect(()=>{
		if( typeof window !== undefined ){
		
			var iframes = document.getElementsByTagName( 'iframe' );
		
			window.addEventListener( 'message', function( e ) {
				var iframe;
		
				if ( 'size' === e.data.message ) {
					iframe = iframes[ e.data.index ];
		
					if ( 'undefined' !== typeof iframe ) {
						iframe.height = parseInt( e.data.height, 10 );
						iframe.scrolling = 'no';
					}
				}
			} );
		
			function watchIframe( i ) {
				iframes[ i ].onload = iframes[ i ].onreadystatechange = function() {
					if ( this.readyState && 'complete' !== this.readyState && 'loaded' !== this.readyState ) {
						return;
					}
		
					iframeInterval.current = setInterval( function() {
						// Send a message to the iframe to ask it to return its size.
						iframes[ i ].contentWindow.postMessage({
							message: 'size',
							index: i
						}, '*' );
					}, 500 );
				};
			};
		
			if ( iframes.length ) {
				for ( var i = 0; i < iframes.length; i ++ ) {
					if ( -1 === iframes[ i ].className.indexOf( 'gfiframe' ) ) {
						continue;
					}
		
					watchIframe( i );
				}
			}

		} 

		return () => {
			clearInterval(iframeInterval.current);
			window.removeEventListener("message", ()=>{});
		}
		
	},[]);



	return (
		<Layout>
			<Seo post={contact} />
			<div className={styleSheet.top}>
				<div className={styleSheet.left}>
					<div className={styleSheet.lefttop}>
						{ mobileNavigationLogo && <Link to="/" className={styleSheet.logolink}><FluidImage image={mobileNavigationLogo} passedClass={styleSheet.logo} loading={"eager"} /></Link>}
						<h1 className={styleSheet.contactname} dangerouslySetInnerHTML={{__html: title}}></h1>
					</div>
					<div className={styleSheet.leftbottom}>
						{ blurb && <div className={styleSheet.contactheading} dangerouslySetInnerHTML={{__html: blurb}}></div> }
						{ requestAppointmentLinkText && <button onClick={showModal} onKeyDown={(e) => handleApptKeyDown(e)} className={styleSheet.request} aria-expanded={showAppointmentModal.modalOpen ? true : false}>{requestAppointmentLinkText}</button> }
						{ !requestAppointmentLinkText && <button onClick={showModal} onKeyDown={(e) => handleApptKeyDown(e)} className={styleSheet.request} aria-expanded={showAppointmentModal.modalOpen ? true : false}>Make an appointment</button> }
					</div>
				</div>
				<div className={styleSheet.right}>
					{ introBGMobile && <FluidImage image={introBGMobile} passedClass={styleSheet.bgmobile} loading={"eager"} /> }
					{ introBGTablet && <FluidImage image={introBGTablet} passedClass={styleSheet.bgtablet} loading={"eager"} /> }
					{ introBGDesktop && <FluidImage image={introBGDesktop} passedClass={styleSheet.bgdesktop} loading={"eager"} /> }
					{ introBGXL && <FluidImage image={introBGXL} passedClass={styleSheet.bgxl} loading={"eager"} /> }
					{ mobileNavigationLogo && <Link to="/" className={styleSheet.logolink}><FluidImage image={mobileNavigationLogo} passedClass={styleSheet.logo} loading={"eager"} /></Link>}
					<h1 className={styleSheet.contactname} dangerouslySetInnerHTML={{__html: title}} data-sal="slide-up" data-sal-delay="200" data-sal-easing="ease-in"></h1>
					{ introHeading && <div className={styleSheet.heading} data-sal="slide-up" data-sal-delay="300" data-sal-easing="ease-in">{introHeading}</div> }
					{ introBlurb && <div className={styleSheet.blurb} dangerouslySetInnerHTML={{__html: introBlurb}} data-sal="slide-up" data-sal-delay="400" data-sal-easing="ease-in"></div> }
				</div>
			</div>
			<div className={styleSheet.bottom}>
				<div className={styleSheet.left}>
					<div className={styleSheet.insurance}>
						<span className={styleSheet.heading}>{ insurancesHeading ? insurancesHeading : 'We accept the following insurance.' }</span>
						<div className={styleSheet.insurancebottom}>
							{ insurances && insurances.map( (insurance, index) => {
								const image = insurance.ACFInsurance.logo;
								return image ? <FluidImage key={`insuranceimg-${index}`} image={image} passedClass={styleSheet.insuranceimage} /> : null;
							})}
						</div>
					</div>
				</div>
				<div className={styleSheet.right}>
					<div className={styleSheet.contactcontent}>
						<div className={styleSheet.contentleft}>
              {contactHeading && <h2 className={styleSheet.contactheading}>{contactHeading}</h2>}
							{ content && <div className="wpcontent" dangerouslySetInnerHTML={{__html: content}}></div> }
							<div className={styleSheet.linkcards}>
								<div className={styleSheet.linkcard}>
									{ contactEmail.emailHeadline && <span className={styleSheet.title} dangerouslySetInnerHTML={{__html: contactEmail.emailHeadline }}></span>}
									{ contactEmail.emailContent && <div dangerouslySetInnerHTML={{__html: contactEmail.emailContent}}></div> }
								</div>
								<div className={styleSheet.linkcard}>
									{ contactCall.callHeadline && <span className={styleSheet.title} dangerouslySetInnerHTML={{__html: contactCall.callHeadline }}></span>}
									{ contactCall.callContent && <div dangerouslySetInnerHTML={{__html: contactCall.callContent}}></div> }
								</div>
								<div className={styleSheet.linkcard}>
									{ contactVisit.visitHeadline && <span className={styleSheet.title} dangerouslySetInnerHTML={{__html: contactVisit.visitHeadline }}></span>}
									{ contactVisit.visitContent && <div dangerouslySetInnerHTML={{__html: contactVisit.visitContent}}></div> }
								</div>
							</div>

              {showForm && (
                <div className={styleSheet.formWrap}>
                  <h2 className={styleSheet.formHeading}>{formHeading}</h2>
                  {nearestLoc?.databaseId && (
                    <iframe 
                      title="Gravity Form" 
                      src={`${process.env.GATSBY_WP_BASE_URL}/gfembed?f=` + gFormID + '&current_location=' + nearestLoc.databaseId + '&current_state=' + nearestLoc?.ACFLocation?.state} 
                      width="100%" height="885" frameBorder="0" className="gfiframe"></iframe>
                  )}
                </div>
              )}
						</div>
					</div>
				</div>
			</div>
		</Layout>
	);
};

export const query = graphql`query ($id: String!) {
  contact: wpPage(id: {eq: $id}) {
    id
    title
    ACFContact {
      contact {
        left {
          blurb
          insurancesHeading
          requestAppointmentLinkText
        }
        right {
          introContent {
            blurb
            heading
          }
          contactHeading
          contactContent
          email {
            emailHeadline
            emailContent
          }
          call {
            callHeadline
            callContent
          }
          visit {
            visitHeadline
            visitContent
          }
          showContactForm
          contactFormHeading
        }
      }
    }
    seo {
      title
      metaDesc
      focuskw
      metaKeywords
      metaRobotsNoindex
      metaRobotsNofollow
      opengraphTitle
      opengraphDescription
      opengraphImage {
        altText
        sourceUrl
        srcSet
      }
      twitterTitle
      twitterDescription
      twitterImage {
        altText
        sourceUrl
        srcSet
      }
      canonical
      cornerstone
      schema {
        articleType
        pageType
        raw
      }
    }
  }
  options: wp {
    headerSection {
      ACFHeaderOptions {
        mainNavigationSettings {
          mobileNavigationLogo {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(width: 420, layout: CONSTRAINED, quality: 100)
              }
              publicURL
              extension
            }
          }
        }
      }
    }
  }
  arrowBlue: file(name: {eq: "arrow_blue"}) {
    childImageSharp {
      gatsbyImageData(width: 9, layout: FIXED)
    }
  }
  introBGMobile: file(name: {eq: "patientinfobg"}) {
    childImageSharp {
      gatsbyImageData(width: 699, layout: CONSTRAINED)
    }
  }
  introBGTablet: file(name: {eq: "patientinfobg"}) {
    childImageSharp {
      gatsbyImageData(width: 550, layout: CONSTRAINED)
    }
  }
  introBGDesktop: file(name: {eq: "patientinfobg"}) {
    childImageSharp {
      gatsbyImageData(width: 840, layout: CONSTRAINED)
    }
  }
  introBGXL: file(name: {eq: "patientinfobg"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
  insurance: allWpInsurance {
    nodes {
      ACFInsurance {
        logo {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(width: 420, layout: CONSTRAINED)
            }
            publicURL
            extension
          }
        }
      }
    }
  }
  locations: allWpLocation(sort: {fields: title, order: ASC}) {
    nodes {
      id
      title
      link
      databaseId
      birdeyeLocation {
				businessId
				timezone
				hoursOfOperations {
					day
					isOpen
					workingHours {
						startHour
						endHour
					}
				}
				location {
					lat
					lng
					address1
					address2
					city
					state
					zip
				}
				name
				phone
			}
      WpOrthodontists {
        ACFDentist {
          briefDescription
          fullLengthImage {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(width: 600, placeholder: NONE, layout: CONSTRAINED)
              }
              publicURL
              extension
            }
          }
					headshot {
						localFile {
							childImageSharp {
								gatsbyImageData(width: 280, layout: CONSTRAINED)
							}
						}
					}
        }
        title
        link
      }
      ACFLocation {
        state
        bookMyAppointmentLinkText
        menuTitle
        briefDescription
        locationInfo {
          left {
            dentists {
              ... on WpOrthodontist {
                id
                ACFDentist {
                  briefDescription
                  fullLengthImage {
                    altText
                    localFile {
                      childImageSharp {
                        gatsbyImageData(width: 600, placeholder: NONE, layout: CONSTRAINED)
                      }
                      publicURL
                      extension
                    }
                  }
									headshot {
										localFile {
											childImageSharp {
												gatsbyImageData(width: 280, layout: CONSTRAINED)
											}
										}
									}
                }
                title
                link
              }
            }
          }
        }
      }
    }
  }
}
`;

export default ContactTemplate;
